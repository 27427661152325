import { Outlet } from "react-router-dom";


const Main = () => {
  return (
    <>
      {/* <Header /> */}
      <div id="main-app">
        {/* <VerticalMenu /> */}
        <div className="app-body kpi-dahboard-page">
          <Outlet />
        </div>
      </div>
    </>
  );
};

export default Main;
