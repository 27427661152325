import {
  Routes,
  Route,
  RoutesProps,
  useLocation,
  useNavigate,
} from "react-router-dom";

import React, { Suspense, useEffect } from "react";
import Main from "../components/Main";
import ProtectedRoute from "./ProtectedRoute";

const Navigation = () => {
  interface ProtectedRouteProps extends RoutesProps {
    authenticationPath: string;
  }
  const defaultProtectedRouteProps: Omit<ProtectedRouteProps, "outlet"> = {
    authenticationPath: "/login",
  };

  const Login = React.lazy(() => import("../components/Login/Login"));

  const Dashboardultimate = React.lazy(
    () => import("../components/Ultimate/Ultimate")
  );
  const Dashboard = React.lazy(
    () => import("../components/Dashboard/Dashboard")
  );
  const Dashboardaverage = React.lazy(
    () => import("../components/Average/Average")
  );
  const SelectStation = React.lazy(
    () => import("../components/Login/SelectStation")
  );

  const location = useLocation();
  let navigate = useNavigate();

  // Define the routes you want to protect
  const protectedRoutes = [
    "/login",
    "/dashboard-ultrapro/:company_id/:machine_id",
    "/select-station",
    "/dashboard-ultimate/:company_id/:machine_id",
    "/dashboard-vanilla/:company_id/:machine_id",
  ];

  const isProtectedRoute = protectedRoutes.includes(location.pathname);

  useEffect(() => {
    if (!isProtectedRoute) {
      navigate("/login");
    }
  }, []);
  return (
    <>
      <div id="main-wraper">
        <Routes>
          <Route
            path="/login"
            element={
              <Suspense fallback={<></>}>
                <Login />
              </Suspense>
            }
          />
          <Route
            path="/"
            element={
              <ProtectedRoute
                {...defaultProtectedRouteProps}
                outlet={<Main />}
              />
            }
          >
            <Route
              path="/dashboard-ultrapro/:company_id/:machine_id"
              element={
                <Suspense fallback={<></>}>
                  <Dashboard />
                </Suspense>
              }
            />
            <Route
              path="/select-station"
              element={
                <Suspense fallback={<></>}>
                  <SelectStation />
                </Suspense>
              }
            />
            <Route
              path="/dashboard-ultimate/:company_id/:machine_id"
              element={
                <Suspense fallback={<></>}>
                  <Dashboardultimate />
                </Suspense>
              }
            />
            <Route
              path="/dashboard-vanilla/:company_id/:machine_id"
              element={
                <Suspense fallback={<></>}>
                  <Dashboardaverage />
                </Suspense>
              }
            />
          </Route>
        </Routes>
      </div>
    </>
  );
};

export default Navigation;
